<template>
  <base-page>
    <form @submit.prevent.stop="submit" :disabled="pending">
      <h2 class="title q-ma-none">
        DONNÉES VIZAGENCE DU {{$formatDate(updatedOn)}}
      </h2>
      <div class="row" v-if="renderEntityCard" >
        <div class="content col col-10">
          <div class="headings row">
            <h5 class="col-5 title-h5 text-divergence text-weight-regular">PER</h5>
            <div class="col-1"></div>
            <h5 class="col-5 title-h5 text-divergence text-weight-regular">ICX</h5>
          </div>
          <div class="col col-12" v-for="data in dataDivergente" :key="data.id">
            <entity-divergent-card @divergenceValidated="validateDivergence" @divergenceRejected="rejectDivergence" class="q-my-md" :entity="data" />
          </div>
        </div>
        <div class="col col-2">
          <q-btn color="divergence full-width" size="md" unelevated type="submit">{{$t('form.submit')}}</q-btn>
        </div>
      </div>
    </form>
  </base-page>
</template>

<script>
// import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import EntityDivergentCard from './EntityDivergentCard.vue'

export default {
  name: 'Divergence',
  mixins: [BaseForm],
  components: { EntityDivergentCard },

  data () {
    return {
      rejectedIds: [],
      validatedIds: [],
      isFormOpen: false,
      dataDivergente: [],
      dataDivergenceBack: [],
      renderEntityCard: true,
      pending: false,
      updatedOn: ''
    }
  },

  props: {
    'action': { type: String, default: 'divergence/make' },
    'form_model': {}
  },

  watch: {
    dataDivergenceBack (newVal, oldVal) {
      if (this.dataDivergenceBack.length === 0) return
      this.dataDivergente = []

      let getDivergeances = (matched, type, isTemporaire) => {
        if (type === 'entity') {
          let divergeances = isTemporaire ? matched.entity_temporaire : matched
          let keyVal = []
          if (matched.entity_temporaire.divergeances) {
            JSON.parse(matched.entity_temporaire.divergeances).forEach(key => {
              keyVal.push({
                key: key,
                value: divergeances[key]
              })
            })
          }

          return keyVal
        } else if (type === 'agents' || type === 'wallets') {
          let keysDivergente = []
          for (let i = 0; i < matched.entity_temporaire[type].length; i++) {
            let data = matched.entity_temporaire[type][i]

            if (data.divergeances) {
              // data.divergeances = JSON.parse(data.divergeances)
              keysDivergente[i] = JSON.parse(data.divergeances)
            } else {
              keysDivergente[i] = []
            }
          }

          let result = []
          let datas = isTemporaire ? matched.entity_temporaire[type] : matched[type]
          for (let i = 0; i < datas.length; i++) {
            let data = datas[i]

            if (keysDivergente[i] && keysDivergente[i].length > 0) {
              let keyVal = []
              keysDivergente[i].forEach(key => {
                keyVal.push({
                  key: key,
                  value: data[key]
                })
              })

              result.push({
                label: type === 'wallets' ? `${data.NOMPTF} ${data.CODEICX}` : `${data.TITULAIRE}`,
                data: keyVal
              })
              console.log(matched, result, i, keysDivergente[i], data)
            } else {
              result.push([])
            }
          }

          return result
        }
      }

      this.dataDivergente.push(...this.dataDivergenceBack[0].matched.map(matched => {
        return {
          type: 'matched',
          per: {
            id: matched.id,
            cible_id: matched.id,
            entityName: matched.label,
            regionName: this.getRegionName(matched.region_id),
            agents: matched.agents.map(agent => ({ label: agent.highlighted ? `<strong class="text-red">${agent.TITULAIRE}</strong>` : agent.TITULAIRE })),
            wallets: matched.wallets.map(wallet => ({ label: wallet.highlighted ? `<strong class="text-red">${wallet.NOMPTF} ${wallet.CODEICX}</strong>` : `${wallet.NOMPTF} ${wallet.CODEICX}`, highlighted: wallet.highlighted })),
            entityDivergeances: getDivergeances(matched, 'entity', false),
            agentsDivergeances: getDivergeances(matched, 'agents', false),
            walletsDivergeances: getDivergeances(matched, 'wallets', false)
          },
          icx: {
            id: matched.entity_temporaire.id,
            cible_id: matched.entity_temporaire.id,
            entityName: matched.entity_temporaire.label,
            regionName: this.getRegionName(matched.entity_temporaire.region_id),
            agents: matched.entity_temporaire.agents.map(agent => ({ label: agent.highlighted ? `<strong class="text-red">${agent.TITULAIRE}</strong>` : agent.TITULAIRE })),
            wallets: matched.entity_temporaire.wallets.map(wallet => ({ label: wallet.highlighted ? `<strong class="text-red">${wallet.NOMPTF} ${wallet.CODEICX}</strong>` : `${wallet.NOMPTF} ${wallet.CODEICX}`, highlighted: wallet.highlighted })),
            entityDivergeances: getDivergeances(matched, 'entity', true),
            agentsDivergeances: getDivergeances(matched, 'agents', true),
            walletsDivergeances: getDivergeances(matched, 'wallets', true)
          }
        }
      }))

      let notMatchedPer = this.dataDivergenceBack[1].not_matched['entités_réseau'].map(not_matched => {
        return {
          type: 'not_matched',
          per: {
            id: not_matched.id,
            cible_id: not_matched.id,
            entityName: not_matched.label,
            regionName: this.getRegionName(not_matched.region_id),
            agents: not_matched.agents.map(agent => ({ label: agent.highlighted ? `<strong class="text-red">${agent.TITULAIRE}</strong>` : agent.TITULAIRE })),
            wallets: not_matched.wallets.map(wallet => ({ label: wallet.highlighted ? `<strong class="text-red">${wallet.NOMPTF} ${wallet.CODEICX}</strong>` : `${wallet.NOMPTF} ${wallet.CODEICX}`, highlighted: wallet.highlighted })),
            fieldDetails: {
              entity: {
                region_name: this.getRegionName(not_matched.region_id),
                label: not_matched.label,
                type_agence: not_matched.type_agence
              },
              wallets: (
                not_matched.wallets.map(wallet => ({
                  label: `${wallet.NOMPTF} ${wallet.CODEICX}`,
                  data: {
                    NOMPTF: wallet.NOMPTF,
                    CODEICX: wallet.CODEICX,
                    TITULAIRE: wallet.TITULAIRE,
                    ADRESSE5: wallet.ADRESSE5,
                    CDPOSTAL: wallet.CDPOSTAL,
                    VILLE: wallet.VILLE,
                    icra: wallet.icra,
                    DATE_NOMI: wallet.DATE_NOMI,
                    CODE_RGT: wallet.CODE_RGT,
                    TYPE_RGT: wallet.TYPE_RGT
                  }
                }))
              ),
              agents: not_matched.agents.map(agent => ({
                label: `${agent.TITULAIRE}`,
                data: {
                  TITULAIRE: agent.TITULAIRE,
                  DATE_AGENT: agent.DATE_AGENT,
                  DATE_NAIS: agent.DATE_NAIS,
                  TAUX: agent.TAUX
                }
              }))
            }
          },
          icx: {}
        }
      })

      let notMatchedIcx = this.dataDivergenceBack[1].not_matched['entités_temporaires'].map(not_matched => {
        return {
          type: 'not_matched',
          per: {},
          icx: {
            id: not_matched.id,
            cible_id: not_matched.id,
            entityName: not_matched.label,
            regionName: this.getRegionName(not_matched.region_id),
            agents: not_matched.agents.map(agent => ({ label: agent.highlighted ? `<strong class="text-red">${agent.TITULAIRE}</strong>` : agent.TITULAIRE })),
            wallets: not_matched.wallets.map(wallet => ({ label: wallet.highlighted ? `<strong class="text-red">${wallet.NOMPTF} ${wallet.CODEICX}</strong>` : `${wallet.NOMPTF} ${wallet.CODEICX}`, highlighted: wallet.highlighted })),
            fieldDetails: {
              entity: {
                region_name: this.getRegionName(not_matched.region_id),
                label: not_matched.label,
                type_agence: not_matched.type_agence
              },
              wallets: (
                not_matched.wallets.map(wallet => ({
                  label: `${wallet.NOMPTF} ${wallet.CODEICX}`,
                  data: {
                    NOMPTF: wallet.NOMPTF,
                    CODEICX: wallet.CODEICX,
                    TITULAIRE: wallet.TITULAIRE,
                    ADRESSE5: wallet.ADRESSE5,
                    CDPOSTAL: wallet.CDPOSTAL,
                    VILLE: wallet.VILLE,
                    icra: wallet.icra,
                    DATE_NOMI: wallet.DATE_NOMI,
                    CODE_RGT: wallet.CODE_RGT,
                    TYPE_RGT: wallet.TYPE_RGT
                  }
                }))
              ),
              agents: not_matched.agents.map(agent => ({
                label: `${agent.TITULAIRE}`,
                data: {
                  TITULAIRE: agent.TITULAIRE,
                  DATE_AGENT: agent.DATE_AGENT,
                  DATE_NAIS: agent.DATE_NAIS,
                  TAUX: agent.TAUX
                }
              }))
            }
          }
        }
      })

      this.dataDivergente.push(...notMatchedPer)
      this.dataDivergente.push(...notMatchedIcx)
      this.pending = false
    }
  },

  meta () {
    return {
      title: 'Divergences'
    }
  },

  mounted () {
    console.log(this.dataDivergenceBack)
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])

    this.$store.commit('pages/setPageTitle', this.$t('global.divergence'))

    this.$axios.get('/api/divergence', { })
      .then(response => response.data)
      .then(results => {
        this.updatedOn = results[2].date
        this.dataDivergenceBack = results
      })
      .catch()
      .finally()
  },
  methods: {
    submit () {
      this.$confirm('Êtes-vous sûr de vouloir sauvegarder les changements ?', this.$t('form.save')).onOk(() => {
        this.pending = true
        this.$axios.post('/api/divergence', this.formData)
          .then(response => response.data)
          .then(results => {
            console.log(results)
            this.$axios.get('/api/divergence', { })
              .then(response => response.data)
              .then(results => {
                this.formData.rejectedIds = this.rejectedIds = []
                this.formData.validatedIds = this.validatedIds = []

                this.renderEntityCard = false

                this.$nextTick(() => {
                  // Add the component back in
                  this.renderEntityCard = true
                  this.$q.notify({
                    message: 'Données mis à jour',
                    color: 'positive'
                  })
                })
                this.dataDivergenceBack = results
              })
              .catch()
              .finally()
          })
          .catch()
          .finally()
      })
    },

    validateDivergence (validatedIds) {
      if (this.validatedIds.some(v_ids => v_ids.perId === validatedIds.perId || v_ids.icxId === validatedIds.icxId)) {
        this.validatedIds = this.validatedIds.filter(v_ids => v_ids.perId !== validatedIds.perId && v_ids.icxId !== validatedIds.icxId)
      } else this.validatedIds.push(validatedIds)

      // remove ids in validated ids list if existing
      this.rejectedIds = this.rejectedIds.filter(r_ids => r_ids.perId !== validatedIds.perId && r_ids.icxId !== validatedIds.icxId)

      this.formData.rejectedIds = this.rejectedIds
      this.formData.validatedIds = this.validatedIds

      // console.log('validatedIds', this.validatedIds)
      // console.log('rejectedIds', this.rejectedIds)
    },

    rejectDivergence (rejectedIds) {
      if (this.rejectedIds.some(v_ids => v_ids.perId === rejectedIds.perId || v_ids.icxId === rejectedIds.icxId)) {
        this.rejectedIds = this.rejectedIds.filter(v_ids => v_ids.perId !== rejectedIds.perId && v_ids.icxId !== rejectedIds.icxId)
      } else this.rejectedIds.push(rejectedIds)

      // remove ids in validated ids list if existing
      this.validatedIds = this.validatedIds.filter(v_ids => v_ids.perId !== rejectedIds.perId && v_ids.icxId !== rejectedIds.icxId)

      this.formData.rejectedIds = this.rejectedIds
      this.formData.validatedIds = this.validatedIds

      console.log('rejectedIds', this.rejectedIds)
      console.log('validatedIds', this.validatedIds)
    },

    getRegionName (regionId) {
      switch (regionId) {
        case 1: return 'Sud-Ouest'
        case 2: return 'Paris Centre Picardie'
        case 3: return 'Sud-Est'
        case 4: return 'Nord-Est'
        case 5: return 'Ouest'
        case 6: return 'Siège'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .title
    color: $grey-9
    font-size: 1.2rem
    font-weight: 600
</style>
