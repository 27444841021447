<template>
  <div class="row q-gutter-x-md divergente-entity">
    <div class="col col-5 shadow-1 container-entity" @click="rejectEntity($event)">
      <div class="collapsible-item" v-if="entity.per && entity.per.id" :entity-per-id="entity.per.id">
        <div class="expand-button" @click="toggleContainer($event)" v-if="entity.per.id">
          <q-avatar size="1.4rem" color="divergence" text-color="white" icon="keyboard_arrow_down" />
        </div>
        <base-card v-bind="entity" :entity-per-id="entity.per.id" clickable class="col-12 entity-per q-pa-small full-height" v-if="entity.per.id">
          <div class="details">
            <q-item-label class="text-weight-bold">{{entity.per.entityName}}</q-item-label>

            <div class="row justify-between">
              <q-item-label caption>{{entity.per.regionName}}</q-item-label>
              <q-item-label caption>
                <router-link :to="{ name: `entity-show`, params: { id: entity.per.cible_id } }" target="_blank">
                  <span class="text-divergence">Lien entité ></span>
                </router-link>
              </q-item-label>
            </div>

            <div class="row q-gutter-x-md q-my-md">
              <q-avatar size="1.5rem" color="divergence" text-color="white" icon="accessibility" />
              <div class="">
                <q-item-label v-for="agent in entity.per.agents" :key="agent.id" v-html="agent.label" caption></q-item-label>
              </div>
            </div>

            <div class="row q-gutter-x-md q-my-sm">
              <q-avatar size="1.5rem" color="divergence" text-color="white" icon="account_balance_wallet" />
              <div class="">
                <q-item-label v-for="wallet in entity.per.wallets" :key="wallet.id" v-html="wallet.label" caption></q-item-label>
              </div>
            </div>
          </div>

          <div class="row q-gutter-x-md q-my-sm" v-if="isSingleEntity()">
            <div class="fit">
              <div class="entity-field-divergent row q-gutter-x-sm q-mb-sm">
                <q-avatar size="1.5rem" color="grey-6" text-color="white" icon="info" />
                <q-item-label class="text-uppercase text-grey-6 text-weight-regular" caption> détails des champs </q-item-label>
              </div>

              <div class="q-mb-sm">
                <q-item-label class="text-weight-bold">Entité</q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(entity.per.fieldDetails.entity).map(key => ({key: key, value: entity.per.fieldDetails.entity[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="text-weight-bold">Portefeuilles</q-item-label>
              <div class="row q-mb-sm" v-for="(wallet, index) in entity.per.fieldDetails.wallets" :key="index" caption>
                <q-item-label class="text-divergence" v-html="wallet.label" caption></q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(wallet.data).map(key => ({key: key, value: wallet.data[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="text-weight-bold">Agents</q-item-label>
              <div class="row q-mb-sm" v-for="(agent, index) in entity.per.fieldDetails.agents" :key="index" caption>
                <q-item-label class="text-divergence" v-html="agent.label" caption></q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(agent.data).map(key => ({key: key, value: agent.data[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>
            </div>
          </div>

          <div class="row q-gutter-x-md q-my-sm" v-else>
            <div class="fit">
              <div class="entity-field-divergent row q-gutter-x-sm q-mb-sm">
                <q-avatar size="1.5rem" color="negative" text-color="white" icon="warning" />
                <q-item-label class="text-uppercase text-negative text-weight-regular" caption> liste des divergences </q-item-label>
              </div>

              <div class="q-mb-sm">
                <q-item-label class="row text-weight-bold">Entité</q-item-label>
                <div class="row" v-for="(data, index) in entity.per.entityDivergeances" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="row text-weight-bold">Portefeuilles</q-item-label>
              <div class="row q-mb-sm" v-for="(wallets, index) in entity.per.walletsDivergeances" :key="index" caption>
                <q-item-label class="row text-divergence" v-html="wallets.label" caption></q-item-label>
                <div class="row" v-for="(wallet, index) in wallets.data" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ wallet.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ wallet.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="row text-weight-bold">Agents</q-item-label>
              <div class="row q-mb-sm" v-for="(agents, index) in entity.per.agentsDivergeances" :key="index" caption>
                <q-item-label class="text-divergence" v-html="agents.label" caption></q-item-label>
                <div class="row" v-for="(agent, index) in agents.data" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ agent.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ agent.value }} </q-item-label>
                </div>
              </div>
            </div>
          </div>
        </base-card>
      </div>
    </div>
    <div class="col col column items-center justify-center action-button">
      <q-icon clickable @click="validateEntity($event)" size="1rem" color="white" name="arrow_back" :class="{'button-validate': true, 'q-pa-md': true, 'q-mb-xs': true, 'justify-center': true, 'bg-grey-4': true}" />
      <q-icon clickable @click="rejectEntity($event)" size="1rem" color="white" name="close" :class="{'button-reject': true, 'q-pa-md': true, 'q-mb-xs': true, 'justify-center': true, 'bg-grey-4': true}" />
    </div>
    <div class="col col-6 shadow-1 container-entity container-icx" @click="validateEntity($event)" ondrop="drop(event)" ondragover="allowDrop(event)">
      <div class="col-12 full-height entity-icx collapsible-item" clickable v-if="entity.icx && entity.icx.id"  :entity-icx-id="entity.icx.id" ondragstart="drag(event)" draggable="true" :style="{ cursor: 'move' }">
        <div class="expand-button" @click="toggleContainer($event)" v-if="entity.icx.id">
          <q-avatar size="1.4rem" color="divergence" text-color="white" icon="keyboard_arrow_down" />
        </div>
        <base-card v-bind="entity" class="q-pa-small full-height shadow-1" v-if="entity.icx.id" draggable>
          <div class="details">
            <q-item-label class="text-weight-bold">{{entity.icx.entityName}}</q-item-label>
            <q-item-label caption>{{entity.icx.regionName}}</q-item-label>

            <div class="row q-gutter-x-md q-my-md">
              <q-avatar size="1.5rem" color="divergence" text-color="white" icon="accessibility" />
              <div class="">
                <q-item-label v-for="agent in entity.icx.agents" :key="agent.id" v-html="agent.label" caption></q-item-label>
              </div>
            </div>

            <div class="row q-gutter-x-md q-my-sm">
              <q-avatar size="1.5rem" color="divergence" text-color="white" icon="account_balance_wallet" />
              <div class="">
                <q-item-label v-for="wallet in entity.icx.wallets" :key="wallet.id" v-html="wallet.label" caption></q-item-label>
              </div>
            </div>
          </div>

          <div class="row q-gutter-x-md q-my-sm" v-if="isSingleEntity()">
            <div class="fit">
              <div class="entity-field-divergent row q-gutter-x-sm q-mb-sm">
                <q-avatar size="1.5rem" color="grey-6" text-color="white" icon="info" />
                <q-item-label class="text-uppercase text-grey-6 text-weight-regular" caption> détails des champs </q-item-label>
              </div>

              <div class="q-mb-sm">
                <q-item-label class="text-weight-bold">Entité</q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(entity.icx.fieldDetails.entity).map(key => ({key: key, value: entity.icx.fieldDetails.entity[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="text-weight-bold">Portefeuilles</q-item-label>
              <div class="row q-mb-sm" v-for="(wallet, index) in entity.icx.fieldDetails.wallets" :key="index" caption>
                <q-item-label class="text-divergence" v-html="wallet.label" caption></q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(wallet.data).map(key => ({key: key, value: wallet.data[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="text-weight-bold">Agents</q-item-label>
              <div class="row q-mb-sm" v-for="(agent, index) in entity.icx.fieldDetails.agents" :key="index" caption>
                <q-item-label class="text-divergence" v-html="agent.label" caption></q-item-label>
                <div class="row" v-for="(data, index) in Object.keys(agent.data).map(key => ({key: key, value: agent.data[key]}))" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>
            </div>
          </div>

          <div class="row q-gutter-x-md q-my-sm" v-else>
            <div class="fit">
              <div class="entity-field-divergent row q-gutter-x-sm q-mb-sm">
                <q-avatar size="1.5rem" color="negative" text-color="white" icon="warning" />
                <q-item-label class="text-uppercase text-negative text-weight-regular" caption> liste des divergences </q-item-label>
              </div>

              <div class="q-mb-sm">
                <q-item-label class="row text-weight-bold">Entité</q-item-label>
                <div class="row" v-for="(data, index) in entity.icx.entityDivergeances" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ data.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ data.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="row text-weight-bold">Portefeuilles</q-item-label>
              <div class="row q-mb-sm" v-for="(wallets, index) in entity.icx.walletsDivergeances" :key="index" caption>
                <q-item-label class="row text-divergence" v-html="wallets.label" caption></q-item-label>
                <div class="row" v-for="(wallet, index) in wallets.data" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ wallet.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ wallet.value }} </q-item-label>
                </div>
              </div>

              <q-item-label class="row text-weight-bold">Agents</q-item-label>
              <div class="row q-mb-sm" v-for="(agents, index) in entity.icx.agentsDivergeances" :key="index" caption>
                <q-item-label class="row text-divergence" v-html="agents.label" caption></q-item-label>
                <div class="row" v-for="(agent, index) in agents.data" :key="index">
                  <q-item-label class="text-weight-bold q-mx-sm" caption> {{ agent.key }}:</q-item-label>
                  <q-item-label class="text-weight-regular text-negative" style="margin-top: 0" caption> {{ agent.value }} </q-item-label>
                </div>
              </div>
            </div>
          </div>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EntityDivergentCard',
  created () {
    window.drag = (event) => {
      // console.log(event, 'mikisaka', event.target.getAttribute('entity-icx-id'))
      event.dataTransfer.setData('entity-icx-id', event.target.getAttribute('entity-icx-id'))
    }

    window.allowDrop = (event) => {
      event.preventDefault()
    }

    window.drop = (event) => {
      event.preventDefault()
      // console.log(event, 'vita mikisaka', event.dataTransfer.getData('entity-per-id'))

      if (event.target.className.indexOf('container-icx') === -1) return

      this.expandContainer(event)

      let entityIcxId = event.dataTransfer.getData('entity-icx-id')
      let elementToDrop = document.querySelectorAll(`[entity-icx-id="${entityIcxId}"]`)[0]
      elementToDrop.classList.remove('entity-activated')
      event.target.appendChild(elementToDrop)
    }
  },
  props: {
    'entity': { type: Object }
  },
  mounted () {
    let entityTarget = document.querySelectorAll(`[entity-icx-id='${this.entity.icx.id}']`)[0]
    if (!entityTarget) entityTarget = document.querySelectorAll(`[entity-per-id='${this.entity.per.id}']`)[0]
    let entityDivergenteElement = entityTarget.closest('.divergente-entity')

    let detailsElements = entityDivergenteElement.getElementsByClassName('details')
    let heightBlock = 0
    for (let i = 0; i < detailsElements.length; i++) {
      if (heightBlock < detailsElements[i].offsetHeight) heightBlock = detailsElements[i].offsetHeight
    }

    for (let i = 0; i < detailsElements.length; i++) {
      detailsElements[i].style['height'] = `${heightBlock}px`
    }

    var entityElements = entityDivergenteElement.getElementsByClassName('collapsible-item')
    for (let i = 0; i < entityElements.length; i++) {
      entityElements[i].style['max-height'] = `${heightBlock + 70}px`
    }
  },
  methods: {
    validateEntity (event) {
      let entityDivergenteElement = event.target.closest('.divergente-entity')
      if (!entityDivergenteElement.querySelectorAll(`[entity-icx-id]`)[0]) return
      if (event.target.closest('.expand-button')) return

      // Change button color
      // -- the validate button
      entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.remove('bg-grey-4')
      entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.add('bg-positive')

      // -- the reject button
      entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.add('bg-grey-4')
      entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.remove('bg-negative')

      // -- the icx entity
      let entityIcxElement = entityDivergenteElement.querySelectorAll(`[entity-icx-id]`)[0]
      if (entityIcxElement) {
        if (entityIcxElement.classList.contains('entity-activated')) {
          // The card
          entityIcxElement.classList.remove('entity-activated')

          // The button
          entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.add('bg-grey-4')
          entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.remove('bg-positive')
        } else entityIcxElement.classList.add('entity-activated')
      }

      // -- the per entity
      let entityPerElement = entityDivergenteElement.querySelectorAll(`[entity-per-id]`)[0]
      if (entityPerElement) entityPerElement.classList.remove('entity-activated')

      // Get icx and per id and emit value
      let entityDivergenteIds = this.getEntityDivergenteIds(entityDivergenteElement)
      this.$emit('divergenceValidated', entityDivergenteIds)
    },
    rejectEntity (event) {
      let entityDivergenteElement = event.target.closest('.divergente-entity')
      if (!entityDivergenteElement.querySelectorAll(`[entity-per-id]`)[0]) return
      if (event.target.closest('.expand-button')) return

      // Change elements color
      // -- the validate button
      entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.remove('bg-grey-4')
      entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.add('bg-negative')

      // -- the reject button
      entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.add('bg-grey-4')
      entityDivergenteElement.getElementsByClassName('button-validate')[0].classList.remove('bg-positive')

      // -- the icx entity
      let entityIcxElement = entityDivergenteElement.querySelectorAll(`[entity-icx-id]`)[0]
      if (entityIcxElement) entityIcxElement.classList.remove('entity-activated')

      // -- the per entity
      let entityPerElement = entityDivergenteElement.querySelectorAll(`[entity-per-id]`)[0]
      let classActivatedToAdd = this.isSingleEntity() ? 'entity-single-per-activated' : 'entity-activated'
      if (entityPerElement) {
        if (entityPerElement.classList.contains(classActivatedToAdd)) {
          // The card
          entityPerElement.classList.remove(classActivatedToAdd)

          // The button
          entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.add('bg-grey-4')
          entityDivergenteElement.getElementsByClassName('button-reject')[0].classList.remove('bg-positive')
        } else entityPerElement.classList.add(classActivatedToAdd)
      }

      // Get icx and per id and emit value
      let entityDivergenteIds = this.getEntityDivergenteIds(entityDivergenteElement)
      this.$emit('divergenceRejected', entityDivergenteIds)
    },

    toggleContainer (event) {
      let entityDivergenteElement = event.target.closest('.divergente-entity')

      var entityElements = entityDivergenteElement.getElementsByClassName('collapsible-item')

      for (let i = 0; i < entityElements.length; i++) {
        if (entityElements[i].classList.contains('expand')) {
          entityElements[i].classList.remove('expand')
          continue
        }
        entityElements[i].classList.add('expand')
      }
    },

    expandContainer (event) {
      let entityDivergenteElement = event.target.closest('.divergente-entity')

      var entityElements = entityDivergenteElement.getElementsByClassName('collapsible-item')

      for (let i = 0; i < entityElements.length; i++) {
        entityElements[i].classList.add('expand')
      }
    },

    getEntityDivergenteIds (entityDivergenteElement) {
      let entityIcxElement = entityDivergenteElement.querySelectorAll(`[entity-icx-id]`)[0]
      let icxId = entityIcxElement ? entityIcxElement.getAttribute('entity-icx-id') : null

      let entityPerElement = entityDivergenteElement.querySelectorAll(`[entity-per-id]`)[0]
      let perId = entityPerElement ? entityPerElement.getAttribute('entity-per-id') : null

      return { icxId, perId }
    },

    isSingleEntity () {
      return this.entity.type === 'not_matched'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .entity-activated {
    border: 2px solid #21ba45 !important
  }

  .entity-single-per-activated {
    border: 2px solid #c62828 !important
  }

  .collapsible-item {
    overflow: hidden;
    // transition: ease .5s;

    &.expand {
      max-height unset !important
      height: 100%;
      overflow: visible;
    }
  }

  .container-entity {
    position: relative;
  }

  .expand-button {
    position: absolute;
    bottom: -13px;
    left: calc(50% - 2.5px);
    z-index 50;
    cursor: pointer;
  }

  .entity-field-divergent {
    padding-top: 12px;
    border-top: 1px dashed;
  }
</style>
